<template>
  <v-card class="pa-3">
    <v-card-title class="text-h5"> Remove Inter Account Transfer </v-card-title>
    <v-card-text
      >This will remove all relationships between
      <em>{{ value.transaction_number }}</em> and
      <em>{{ value.related_transaction_number }}</em
      >... but will not delete the transactions themselves.</v-card-text
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        dark
        elevation="0"
        @click="unreconcileInterAccountTransfer()"
      >
        <v-icon left small>mdi-delete</v-icon>
        Delete
      </v-btn>
      <v-btn text @click="passCancelDialog" :loading="this.loading_delete">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { showSnackbar } from "../../../globalActions/index";
export default {
  name: "InterAccountTransferRemoveDialog",
  props: ["remove_and_redo_dialog", "value"],
  data() {
    return {
      loading_delete: false,
    };
  },
  methods: {
    unreconcileInterAccountTransfer() {
      const batch = db.batch();
      //  ACCOUNTING LOGIC
      // 1. Increase Suspense Account totals in correct months
      const suspenseID =
        this.value.transaction_type === "Cash Out"
          ? "44UTKB7Og5aZLcOvaSOl"
          : "RSAgQsASdXZKhQRwMZUz";
      const suspenseRef = db
        .collection("accounting_totals")
        .doc(this.value.transaction_date.slice(0, 7))
        .collection("ledger_accounts")
        .doc(suspenseID);

      batch.update(suspenseRef, {
        monthly_total: firebase.firestore.FieldValue.increment(
          Math.abs(this.value.transaction_amount).toFixed(2)
        ),
      });

      const relatedSuspenseID =
        this.value.related_transaction_type === "Cash Out"
          ? "44UTKB7Og5aZLcOvaSOl"
          : "RSAgQsASdXZKhQRwMZUz";
      const relatedSuspenseRef = db
        .collection("accounting_totals")
        .doc(this.value.related_transaction_date.slice(0, 7))
        .collection("ledger_accounts")
        .doc(relatedSuspenseID);

      batch.update(relatedSuspenseRef, {
        monthly_total: firebase.firestore.FieldValue.increment(
          Math.abs(this.value.transaction_amount).toFixed(2)
        ),
      });
      // 2. Remove related document fields
      const firstDoc = db
        .collection("bank_transactions")
        .doc(this.value.transaction_id);

      const secondDoc = db
        .collection("bank_transactions")
        .doc(this.value.related_transaction_id);
      batch.update(firstDoc, {
        related_transaction_id: firebase.firestore.FieldValue.delete(),
        related_transaction_bank: firebase.firestore.FieldValue.delete(),
        related_transaction_type: firebase.firestore.FieldValue.delete(),
        related_transaction_number: firebase.firestore.FieldValue.delete(),
        related_transaction_date: firebase.firestore.FieldValue.delete(),
        transaction_allocation_status: "No",
      });
      batch.update(secondDoc, {
        related_transaction_id: firebase.firestore.FieldValue.delete(),
        related_transaction_bank: firebase.firestore.FieldValue.delete(),
        related_transaction_type: firebase.firestore.FieldValue.delete(),
        related_transaction_number: firebase.firestore.FieldValue.delete(),
        related_transaction_date: firebase.firestore.FieldValue.delete(),
        transaction_allocation_status: "No",
      });

      batch
        .commit()
        .then(() => {
          showSnackbar("Inter account transfer unreconciled successfully");
        })
        .catch((error) => error);
    },
    passCancelDialog() {
      this.$emit("changeCancelDialog", false);
    },
  },
};
</script>
